import React from 'react'
import styled from 'styled-components'
const Carousel = ({ img1, img2, img3, img4 }) => {
  return (
    <>
      <div className='image-container'>
        <img src={img1} className='carousel-img' />
        <img src={img2} className='carousel-img' />
        <img src={img3} className='carousel-img' />
        <img src={img4} className='carousel-img' />
      </div>
    </>)
}
const Wrapper = styled.section`
 width: 85rem;
 height: 5rem;
 border: solid red 5px;
 .image-container{
 display:grid;
 grid-template-columns: 1fr 1fr 1fr 1fr;
 }
 .carousel-image{
 width:10rem;
 height:12rem;
 border: solid blue 5px;
 }
`
export default Carousel