import React from 'react'
import { MDXProvider } from '@mdx-js/react'
import {
  Headings,
  Blockquote,
  Carousel
} from './src/components/Complete'

const components = {
  p: Headings.myPgh,
  h2: Headings.myH2,
  h4: Headings.myH4,
  blockquote: Blockquote,
  carousel: Carousel

}
export const wrapMDX = ({ element }) => {
  return (
    <MDXProvider components={components}>{element}</MDXProvider>

  )

}
