import React from 'react'
import styled from "styled-components"

const Button = ({ href, children }) => {
  return (
    <>
      <Wrapper>
        <div>
          <button className='btn buy-btn'><a className='buy-btn' href={href}>{children}</a></button>
        </div>
      </Wrapper>
    </>
  )
}
const Wrapper = styled.button`
height:40px;
.buy-btn{
color:white;
}
.buy-btn:hover{
color:grey;
}
`

export default Button
