exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-newsletter-js": () => import("./../../../src/pages/newsletter.js" /* webpackChunkName: "component---src-pages-newsletter-js" */),
  "component---src-pages-shop-js": () => import("./../../../src/pages/shop.js" /* webpackChunkName: "component---src-pages-shop-js" */),
  "component---src-pages-success-js": () => import("./../../../src/pages/success.js" /* webpackChunkName: "component---src-pages-success-js" */),
  "component---src-pages-testimonials-js": () => import("./../../../src/pages/testimonials.js" /* webpackChunkName: "component---src-pages-testimonials-js" */),
  "component---src-templates-category-template-js": () => import("./../../../src/templates/category-template.js" /* webpackChunkName: "component---src-templates-category-template-js" */),
  "component---src-templates-page-template-js": () => import("./../../../src/templates/page-template.js" /* webpackChunkName: "component---src-templates-page-template-js" */),
  "component---src-templates-paginated-remedies-template-js": () => import("./../../../src/templates/paginated-remedies-template.js" /* webpackChunkName: "component---src-templates-paginated-remedies-template-js" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-1-post-number-one-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/1-post-number-one/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-1-post-number-one-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-10-post-number-ten-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/10-post-number-ten/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-10-post-number-ten-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-11-post-number-eleven-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/11-post-number-eleven/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-11-post-number-eleven-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-12-post-number-twelve-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/12-post-number-twelve/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-12-post-number-twelve-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-13-post-number-thirteen-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/13-post-number-thirteen/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-13-post-number-thirteen-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-14-post-number-fourteen-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/14-post-number-fourteen/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-14-post-number-fourteen-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-15-post-number-fifteen-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/15-post-number-fifteen/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-15-post-number-fifteen-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-16-post-number-sixteen-question-001-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/16-post-number-sixteen/question-001.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-16-post-number-sixteen-question-001-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-17-post-number-seventeen-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/17-post-number-seventeen/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-17-post-number-seventeen-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-18-post-number-eighteen-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/18-post-number-eighteen/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-18-post-number-eighteen-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-19-post-number-nineteen-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/19-post-number-nineteen/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-19-post-number-nineteen-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-2-post-number-two-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/2-post-number-two/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-2-post-number-two-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-20-post-number-twenty-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/20-post-number-twenty/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-20-post-number-twenty-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-21-post-number-twentyone-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/21-post-number-twentyone/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-21-post-number-twentyone-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-22-post-number-twentytwo-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/22-post-number-twentytwo/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-22-post-number-twentytwo-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-3-post-number-three-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/3-post-number-three/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-3-post-number-three-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-4-post-number-four-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/4-post-number-four/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-4-post-number-four-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-5-post-number-five-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/5-post-number-five/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-5-post-number-five-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-6-post-number-six-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/6-post-number-six/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-6-post-number-six-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-7-post-number-seven-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/7-post-number-seven/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-7-post-number-seven-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-8-post-number-eight-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/8-post-number-eight/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-8-post-number-eight-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-posts-9-post-number-nine-post-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/posts/9-post-number-nine/post.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-posts-9-post-number-nine-post-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-remedies-1-remedy-number-one-remedy-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/remedies/1-remedy-number-one/remedy.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-remedies-1-remedy-number-one-remedy-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-remedies-2-remedy-number-two-remedy-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/remedies/2-remedy-number-two/remedy.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-remedies-2-remedy-number-two-remedy-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-remedies-3-remedy-number-three-remedy-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/remedies/3-remedy-number-three/remedy.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-remedies-3-remedy-number-three-remedy-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-remedies-4-remedy-number-four-remedy-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/remedies/4-remedy-number-four/remedy.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-remedies-4-remedy-number-four-remedy-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-remedies-5-remedy-number-five-remedy-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/remedies/5-remedy-number-five/remedy.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-remedies-5-remedy-number-five-remedy-mdx" */),
  "component---src-templates-post-template-js-content-file-path-src-remedies-6-remedy-number-six-remedy-mdx": () => import("./../../../src/templates/post-template.js?__contentFilePath=/opt/build/repo/src/remedies/6-remedy-number-six/remedy.mdx" /* webpackChunkName: "component---src-templates-post-template-js-content-file-path-src-remedies-6-remedy-number-six-remedy-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-1-post-number-one-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/1-post-number-one/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-1-post-number-one-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-10-post-number-ten-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/10-post-number-ten/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-10-post-number-ten-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-11-post-number-eleven-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/11-post-number-eleven/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-11-post-number-eleven-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-12-post-number-twelve-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/12-post-number-twelve/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-12-post-number-twelve-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-13-post-number-thirteen-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/13-post-number-thirteen/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-13-post-number-thirteen-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-14-post-number-fourteen-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/14-post-number-fourteen/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-14-post-number-fourteen-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-15-post-number-fifteen-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/15-post-number-fifteen/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-15-post-number-fifteen-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-16-post-number-sixteen-question-001-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/16-post-number-sixteen/question-001.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-16-post-number-sixteen-question-001-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-17-post-number-seventeen-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/17-post-number-seventeen/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-17-post-number-seventeen-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-18-post-number-eighteen-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/18-post-number-eighteen/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-18-post-number-eighteen-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-19-post-number-nineteen-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/19-post-number-nineteen/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-19-post-number-nineteen-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-2-post-number-two-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/2-post-number-two/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-2-post-number-two-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-20-post-number-twenty-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/20-post-number-twenty/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-20-post-number-twenty-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-21-post-number-twentyone-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/21-post-number-twentyone/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-21-post-number-twentyone-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-22-post-number-twentytwo-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/22-post-number-twentytwo/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-22-post-number-twentytwo-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-3-post-number-three-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/3-post-number-three/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-3-post-number-three-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-4-post-number-four-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/4-post-number-four/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-4-post-number-four-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-5-post-number-five-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/5-post-number-five/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-5-post-number-five-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-6-post-number-six-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/6-post-number-six/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-6-post-number-six-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-7-post-number-seven-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/7-post-number-seven/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-7-post-number-seven-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-8-post-number-eight-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/8-post-number-eight/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-8-post-number-eight-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-posts-9-post-number-nine-post-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/posts/9-post-number-nine/post.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-posts-9-post-number-nine-post-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-remedies-1-remedy-number-one-remedy-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/remedies/1-remedy-number-one/remedy.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-remedies-1-remedy-number-one-remedy-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-remedies-2-remedy-number-two-remedy-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/remedies/2-remedy-number-two/remedy.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-remedies-2-remedy-number-two-remedy-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-remedies-3-remedy-number-three-remedy-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/remedies/3-remedy-number-three/remedy.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-remedies-3-remedy-number-three-remedy-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-remedies-4-remedy-number-four-remedy-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/remedies/4-remedy-number-four/remedy.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-remedies-4-remedy-number-four-remedy-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-remedies-5-remedy-number-five-remedy-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/remedies/5-remedy-number-five/remedy.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-remedies-5-remedy-number-five-remedy-mdx" */),
  "component---src-templates-remedy-template-js-content-file-path-src-remedies-6-remedy-number-six-remedy-mdx": () => import("./../../../src/templates/remedy-template.js?__contentFilePath=/opt/build/repo/src/remedies/6-remedy-number-six/remedy.mdx" /* webpackChunkName: "component---src-templates-remedy-template-js-content-file-path-src-remedies-6-remedy-number-six-remedy-mdx" */)
}

