import React from "react";
import styled from "styled-components";
import ReactAudioPlayer from 'react-audio-player';
const AudioPlayer = ({ title }) => {
  return (
    <>
      <Wrapper>
        <h4 className="info">Want to invite the Lord Jesus into your life? Say this prayer with me.</h4>
        <ReactAudioPlayer
          src={title}
          controls
          className="audio-player"
        />
      </Wrapper>
    </>
  )
}
const Wrapper = styled.section` 
//mobile
  border:solid black 1px;
  border-radius:50px;
  background:var(--clr-grey-9);
  max-width:45rem;
  width:20rem;
  margin:0 auto;
  margin-bottom:3rem;
  padding:1rem;
  .audio-player{
    display:block;
    width:16rem;
    margin:0 auto;
    border:solid black 1px;
    border-radius:50px;
    overflow:hidden;
}
  .info{
  text-align:center;
  }
//PC
  @media screen and (min-width:768px){
  width:45rem;
  .audio-player{
  width:35rem;
  }
  }
`
export default AudioPlayer